.events {
  background-image: url("../assets/images/eventbg.jpg");
  background-position: center center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  padding-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.events-container {
  background-color: rgba(0, 0, 0, 0.8);
  height: 80%;
  padding: 20px;
  width: 90%;
  overflow: auto;
}

.event {
  color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.event > button {
  border: 1px solid white;
  padding: 2px;
}

.react-time-picker {
  background-color: white;
  border: none;
  outline: 0;
}

.events-container > h1 {
  color: white;
  font-size: 50px;
  border-bottom: 2px solid white;
}

.events-container > button {
  margin-top: 10px;
  color: white;
  background: linear-gradient(326deg, #781ea1 0%, #a4508b 74%);
  padding: 0 5px;
  border-radius: 5px;
}

.login-container,
.add-container,
.edit-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  position: absolute;
  width: auto;
  height: auto;
  border-radius: 10px;
  background: linear-gradient(326deg, #781ea1 0%, #a4508b 74%);
  z-index: 1;
}

.error-login {
  color: white;
}
.add-container > button {
  padding: 0 5px;
  background-color: white;
  color: black;
}
.edit-container > button {
  padding: 0 5px;
  background-color: white;
  color: black;
}

.add-container > input {
  margin: 5px 0;
  padding: 5px;
  outline: none;
  border-radius: 5px;
}

.edit-container > input {
  margin: 5px 0;
  padding: 5px;
  outline: none;
  border-radius: 5px;
}

.add-button,
.edit-button {
  margin-top: 10px;
  border-radius: 5px;
}

.add-button:hover {
  background: linear-gradient(326deg, #22a11eab 0%, #5ea450 74%);
  color: white;
}

.edit-button:hover {
  background: linear-gradient(326deg, #22a11eab 0%, #5ea450 74%);
  color: white;
}

.add-container,
.edit-container {
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.date-picker-event {
  margin: 5px 0;
  padding: 5px;
  outline: none;
  border-radius: 5px;
}

.time-picker-event {
  margin: 5px 0;
  padding: 5px;
  outline: none;
  border-radius: 5px;
}

.login-container > input {
  margin: 5px 0;
  padding: 5px;
  outline: none;
  border-radius: 5px;
}

.login-container > h1 {
  color: white;
}

.login-container > button {
  background-color: white;
  padding: 0px 5px;
  margin-top: 5px;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.add-event-button {
  margin-left: 5px;
}
