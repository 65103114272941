.event-component > h1,
h2,
p {
  display: flex;
  align-items: center;
}

.event-component > h1 {
  font-size: 20px;
  font-weight: bold;
}
