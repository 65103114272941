.meeting {
  padding-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background-image: url("../assets/images/zoom-picture.jpg");
  background-position: center center;
  background-size: cover;
  overflow-x: hidden;
}

.meeting-container {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  border-color: #d9d9d9;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.meet-container {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  border-color: #d9d9d9;
  background-color: rgba(255, 255, 255, 0.7);
  width: 70%;
  margin-bottom: 10%;
}

.top-container {
  margin-bottom: 20px;
}

.top-container > h1 {
  font-size: 30px;
}

.top-container > p {
  font-size: 20px;
}

.bottom-container > h1 {
  font-size: 30px;
}

.bottom-container > p {
  font-size: 20px;
}

.meeting-container > p {
  font-size: 32px;
  margin-bottom: 10px;
}

.bottom-container > button {
  background-color: #2d8cff;
  height: 52px;
  padding: 0 10px 0 10px;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.meeting-container > button {
  background-color: #4f5863;
  height: 52px;
  padding: 0 10px 0 10px;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  align-items: center;
}

.bottom-container > button:hover{
  transform: scale(1.01)
}

.meeting-container > form > input {
  border: 1px solid black;
  border-radius: 10px;
  padding-left: 10px;
  margin: 10px 0 10px 0;
  outline: none;
  font-size: 25px;
  height: 56px;
  width: 100%;
}

.meeting-container > form > input::placeholder {
  color: rgb(94, 94, 94);
  line-height: normal;
}

.submit_button {
  background-color: #2d8cff;
  width: 193px;
  height: 52px;
  border-radius: 10px;
  font-size: 25px;
  cursor: pointer;
}

.videocamIcon {
  transform: scale(1.3);
  margin-right: 10px;
}

.password-required {
  font-size: 16px !important;
}

@media (max-width: 1242px) {
  .meet-container {
    width: 90%;
  }
}

@media (max-width: 959px) {
  .meet-container {
    width: 90%;
  }
}

@media (max-width: 414px) {
  .meet-container {
    width: 90%;
  }
}
