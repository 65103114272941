.give {
  background-image: url("../assets/images/give-background.jpg");
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.give-container {
  margin-top: 100px;
  color: white;
  padding: 50px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.other-payment {
  font-size: 50px;
}

.zelle-payment {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.payable-to {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 20px;
  border: 1px solid white;
  margin-top: 15px;
}

.payable-to-title {
  font-weight: bold;
  margin-top: 10px;
  font-size: 25px;
}

.payable-to-description {
  font-size: 20px;
  font-weight: normal;
}

.thankyou-message {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.thankyou-message > h1 {
  font-size: 25px;
  font-weight: bold;
}

.thankyou-message > p {
  font-size: 20px;
}

.zelle-payment > h1 {
  font-size: 50px;
}

.zelle-payment > p {
  font-size: 35px;
}

.or-header {
  font-size: 60px;
}

.zelle-payment > img {
  height: 100px;
}

.online-giving {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.online-giving > p {
  font-size: 30px;
}
.online-giving > p:nth-child(2) {
  font-size: 20px;
}


.online-giving > a{
  font-size: 20px;
}

@media only screen and (max-width: 414px) {
  .give-container {
    color: white;
    padding: 10px;
  }

  .thank-you {
    font-size: 50px;
    font-weight: bold;
    font-style: italic;
  }

  .other-payment > h1 {
    font-size: 25px;
  }

  .zelle-payment {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .zelle-payment > p {
    font-size: 25px;
  }

  .or-header {
    font-size: 40px;
  }

  .zelle-payment > img {
    height: 50px;
  }

  .payable-to {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 15px;
    border: 1px solid white;
  }

  .payable-to-title {
    font-weight: bold;
    margin-top: 10px;
    font-size: 25px;
  }
  
  .payable-to-description {
    font-size: 20px;
    font-weight: normal;
  }

  .payable-to > h1 {
    font-size: 15px;
  }

  .thankyou-message > h1 {
    font-size: 20px;
    font-weight: bold;
  }

  .thankyou-message > p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 320px) {
  .give-container {
    color: white;
    padding: 10px;
  }

  .thank-you {
    font-size: 50px;
    font-weight: bold;
    font-style: italic;
  }

  .other-payment > h1 {
    font-size: 20px;
  }

  .zelle-payment {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .zelle-payment > p {
    font-size: 20px;
  }

  .or-header {
    font-size: 35px;
  }

  .zelle-payment > img {
    height: 50px;
  }
}
