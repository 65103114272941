@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

.join-our-team {
  height: auto;
  width: 100%;
  background-image: url("../assets/images/joinourteam.jpg");
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.join-our-team-container {
  color: white;
  width: 80%;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: 20px;
}

.image-join-our-team {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.image-join-our-team > img {
  width: 40%;
  border-radius: 10px;
}

.join-our-team-container > p {
  font-size: 25px;
  font-family: "Josefin Sans";
}

.join-our-team-container-question {
  margin-left: 25px;
}

.join-our-team-container > h1 {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 30px;
  font-family: "Josefin Sans";
  font-size: 35px;
  font-weight: bold;
}

.list-one {
  margin-left: 25px;
  padding-top: 10px;
}

.service-list h2 {
  font-family: "Josefin Sans";
  font-size: 25px;
  background-color: #b874a3;
  padding: 5px;
}

.service {
  cursor: pointer;
}

.service-description {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.more-description {
  padding: 10px;
  display: flex;
  align-items: flex-start;
}

.list-one > p {
  font-size: 25px;
  font-family: "Josefin Sans";
}

.list-one > p > a {
  font-size: 25px;
  font-family: "Josefin Sans";
  color: rgb(216, 99, 216);
}

.join-our-team-container > a {
  font-size: 25px;
  font-family: "Josefin Sans";
  color: white;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgb(216, 99, 216);
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 414px) {
  .join-our-team-container > p {
    font-size: 20px;
    font-family: "Josefin Sans";
  }

  .join-our-team-container-question {
    margin-left: 0;
  }

  .join-our-team-container h1 {
    font-size: 20px;
  }

  .list-one > p {
    font-size: 20px;
  }

  .service-list h2 {
    font-size: 20px;
  }
}

@media (max-width: 390px) {
  .join-our-team-container > p {
    font-size: 20px;
    font-family: "Josefin Sans";
  }

  .join-our-team-container-question {
    margin-left: 0;
  }

  .join-our-team-container h1 {
    font-size: 20px;
  }

  .list-one > p {
    font-size: 20px;
  }

  .service-list h2 {
    font-size: 20px;
  }
}

@media (max-width: 375px) {
  .join-our-team-container > p {
    font-size: 15px;
    font-family: "Josefin Sans";
  }

  .join-our-team-container-question {
    margin-left: 0;
  }

  .join-our-team-container h1 {
    font-size: 15px;
  }

  .list-one > p {
    font-size: 15px;
  }

  .service-list h2 {
    font-size: 15px;
  }
}

@media (max-width: 320px) {
  .join-our-team-container > p {
    font-size: 15px;
    font-family: "Josefin Sans";
  }

  .join-our-team-container-question {
    margin-left: 0;
  }

  .join-our-team-container h1 {
    font-size: 15px;
  }

  .list-one > p {
    font-size: 15px;
  }

  .service-list h2 {
    font-size: 15px;
  }
}
