@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap");

.homepage {
  overflow: hidden;
  position: relative;
  height: auto;
}

.homepage-top-container {
  background-image: url("../assets/images/hp-background-top.jpg");
  background-position: center center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.homepage_text {
  margin-left: auto;
  margin-right: auto;
  bottom: 5%;
  right: 0;
  left: 0;
  width: auto;
  padding: 0 15%;
  border-radius: 10px;
  text-align: center;
}

.homepage_text > p {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 10px;
}

.homepage_text > a {
  color: white;
  font-size: 20px;
  padding: 8px;
  border: 1px solid white;
}

.homepage_text > a:hover {
  background: linear-gradient(326deg, #781ea1 0%, #a4508b 74%);
  color: white;
  border: none;
}

.intro {
  padding: 5% 20% 5% 20%;
}

.intro > h1 {
  font-size: 35px;
  font-weight: 200;
  border-bottom: 1px solid gray;
}

.intro > h1 > span {
  font-weight: 400;
}

.intro > p {
  margin: 20px 0px 20px 0;
  font-size: 20px;
  font-weight: 300;
}

.about-us-button {
  background: linear-gradient(326deg, #a4508b 0%, #781ea1 74%);
  color: white;
  font-size: 20px;
  padding: 8px;
}

.mission-vision {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.vision-image {
  object-fit: contain;
}

@media only screen and (max-width: 414px) {
  .homepage_text > p {
    font-size: 1rem;
    color: white;
    margin-bottom: 10px;
  }
  .homepage_text > a {
    display: none;
  }
  .intro {
    padding: 5% 10% 5% 10%;
  }
  .intro > h1 {
    font-size: 25px;
  }
  .intro > p {
    font-size: 15px;
  }
  .about-us-button {
    font-size: 15px;
  }
  .home-container > video {
    display: none;
  }
}

@media only screen and (max-width: 411px) {
  .homepage_text > p {
    font-size: 1rem;
    color: white;
    margin-bottom: 10px;
  }
  .homepage_text > a {
    display: none;
  }
  .intro {
    padding: 5% 10% 5% 10%;
  }
  .intro > h1 {
    font-size: 25px;
  }
  .intro > p {
    font-size: 15px;
  }
  .about-us-button {
    font-size: 15px;
  }
  .home-container > video {
    display: none;
  }
}
@media only screen and (max-width: 370px) {
  .homepage_text > p {
    font-size: 1rem;
    color: white;
    margin-bottom: 10px;
  }
  .homepage_text > a {
    display: none;
  }
  .intro {
    padding: 5% 10% 5% 10%;
  }
  .intro > h1 {
    font-size: 25px;
  }
  .intro > p {
    font-size: 15px;
  }
  .about-us-button {
    font-size: 15px;
  }
  .home-container > video {
    display: none;
  }
}
@media only screen and (max-width: 320px) {
  .homepage_text > p {
    font-size: 0.7rem;
    color: white;
    margin-bottom: 10px;
  }
  .homepage_text > a {
    display: none;
  }
  .intro {
    padding: 5% 10% 5% 10%;
  }
  .intro > h1 {
    font-size: 25px;
  }
  .intro > p {
    font-size: 15px;
  }
  .about-us-button {
    font-size: 15px;
  }
  .home-container > video {
    display: none;
  }
}
