.connection-card {
  height: auto;
  width: 100%;
  padding-top: 80px;
  background-image: url(../assets/images/connection-card-background.png);
  background-position: center center;
  background-size: cover;
}

.top-title {
  background-image: linear-gradient(326deg, #a4508b 0%, #781ea1 74%);
  color: white;
  padding: 50px 20px;
  font-size: 3em;
  text-align: center;
}

.connection-card-container {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 40px;
}

.connection-card-container h1 {
  font-size: 20px;
  text-align: center;
}

.connection-card img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
}

.submission-container {
  margin-top: 30px;
}

.submission-container > form > div > input,
select,
textarea {
  border: 1px solid black;
  outline: none;
  font-size: 20px;
  padding: 3px 10px;
  border-radius: 10px;
}

.how-did-you-hear-about-us > textarea {
  min-height: 100px;
}
.anything-you-like-to-share > textarea {
  min-height: 100px;
}

.full-name {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.submission-container > form > div > label {
  font-size: 20px;
}

.submission-container > form > div {
  margin: 10px 0;
}

.gender label {
  margin-right: 10px;
}

.status label {
  margin-right: 10px;
}

.tell-me-mor > label {
  margin-right: 10px;
}

.email-phone-address,
.country-city-state-zip,
.tell-me-more,
.how-did-you-hear-about-us,
.anything-you-like-to-share {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.decision-option > input {
  margin-left: 10px;
}

.decision-option > label {
  margin-left: 10px;
}

.send-button {
  background-image: linear-gradient(326deg, #a4508b 0%, #781ea1 74%);
  color: white;
  font-size: 20px;
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.success-sent,
.error-sent {
  text-align: center;
  font-size: 20px;
  color: black;
  width: 50%;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 20px;
}

.success-sent {
  background-color: rgb(164, 255, 164);
}

.error-sent {
  background-color: rgb(252, 118, 118);
}

@media only screen and (max-width: 414px) {
  .connection-card {
    padding-top: 40px;
  }

  .top-title {
    padding: 30px 10px;
    font-size: 2em;
    text-align: center;
  }

  .connection-card-container {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
  }

  .connection-card-container > h1 {
    font-size: 20px;
  }

  .submission-container {
    margin-top: 20px;
  }

  .submission-container > form > div > input,
  select,
  textarea {
    border: 1px solid black;
    outline: none;
    font-size: 20px;
    padding: 3px 10px;
    border-radius: 10px;
  }

  .how-did-you-hear-about-us > textarea {
    min-height: 100px;
  }
  .anything-you-like-to-share > textarea {
    min-height: 100px;
  }

  .full-name {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .submission-container > form > div > label {
    font-size: 20px;
  }

  .submission-container > form > div {
    margin: 10px 0;
  }

  .gender label {
    margin-right: 10px;
  }

  .status label {
    margin-right: 10px;
  }

  .tell-me-mor > label {
    margin-right: 10px;
  }

  .email-phone-address,
  .country-city-state-zip,
  .tell-me-more,
  .how-did-you-hear-about-us,
  .anything-you-like-to-share {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .decision-option > input {
    margin-left: 10px;
  }

  .decision-option > label {
    margin-left: 10px;
  }

  .send-button {
    background-image: linear-gradient(326deg, #a4508b 0%, #781ea1 74%);
    color: white;
    font-size: 20px;
    padding: 8px;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .success-sent,
  .error-sent {
    text-align: center;
    font-size: 20px;
    color: black;
    width: 50%;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 20px;
  }

  .success-sent {
    background-color: rgb(164, 255, 164);
  }

  .error-sent {
    background-color: rgb(252, 118, 118);
  }
}
