@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap");

.about {
}

.body_content {
  width: 100%;
  overflow: hidden;
}

.active_s {
  transform: translate(1000px, 0);
  transition-duration: 400ms;
}

.mission {
  transition-duration: 1s;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
}

.hide-mission {
  display: none;
}

.mission > div > p {
  font-size: 20px;
  color: white;
}

.leadership {
  background-image: url("../assets/images/image18.jpg");
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  width: 100vw;
}

.leadership > h1 {
  font-size: 35px;
  font-weight: 500;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.leadership > img {
  border-radius: 15px;
  margin-top: 20px;
  height: auto;
  width: 40%;
  box-shadow: 5px 5px 15px black;
}

.about_background {
  padding: 0 10% 0 10%;
  margin: 15px 50px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
}

.about_background > h2 {
  margin-top: 10px;
  font-size: 30px;
  color: white;
}

.about_background > p {
  font-size: 20px;
  color: white;
}

.about_background:last-child > p {
  margin-bottom: 20px;
}

.about_background > a {
  background: linear-gradient(326deg, #a4508b 0%, #781ea1 74%);
  color: white;
  font-size: 20px;
  padding: 10px;
  border-radius: 10px;
}

.button_outreach {
  background: linear-gradient(326deg, #a4508b 0%, #781ea1 74%);
  color: white;
  padding: 8px;
  font-size: 20px;
}

.outreach_content {
  background-image: url("../assets/images/helpwallpaper.jpg");
  background-position: center center;
  background-size: cover;
  display: flex;
  height: 100vh;
  padding: 0 100px;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
}

.outreach-content-container {
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
}

.outreach-content-container > h1 {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
}

.outreach-content-container > h2 {
  font-size: 30px;
}

.outreach-content-container > p {
  margin-bottom: 25px;
  font-size: 20px;
  margin-left: 100px;
  border-left: 5px solid white;
  padding-left: 10px;
}

.purposeandpassion {
  background-image: url("../assets/images/purpose-and-passion.jpg");
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 100px;
}

.purpose-container {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
}

.purpose-container > h1 {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  font-family: "Josefin Sans";
}

.purpose-container > h3 {
  font-size: 20px;
  font-family: "Josefin Sans";
}

.whatwebeleive-container {
  background-image: url("../assets/images/believewallpaper.jpg");
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatwebeleive {
  width: 100%;
  padding: 20px 100px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
}

.whatwebeleive > h1 {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
}

.message {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 3px;
  height: auto;
}

.what-we-beleive-option {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  height: 100%;
}

.what-we-beleive-description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.what-we-beleive-option > h1 {
  width: 100%;
  text-align: left;
  background-color: #a4508b;
  background-image: linear-gradient(326deg, #a4508b 0%, #781ea1 74%);
  color: white;
  font-size: 25px;
  padding: 2px 10px;
  height: 100%;
}

.plus-minus-icon {
  background-color: #8e48cf;
  height: 100%;
}

.message > p {
  font-size: 20px;
  margin: 10px 0px;
}

.contact-background-color {
  background-image: linear-gradient(326deg, #a4508b 0%, #781ea1 74%);
  border-radius: 20px;
  padding: 5px;
  height: auto;
  width: 95%;
  border: 1px solid black;
}

@media only screen and (max-width: 360px) {
  .up-arrow-icon {
    display: none;
  }
  .whoweare {
    padding: 15px;
    flex-direction: column;
  }
  .vision > h1 {
    font-size: 30px;
  }
  .vision > h2 {
    font-size: 20px;
  }
  .vision > p {
    font-size: 15px;
  }
  .vision > button {
    font-size: 10px;
    padding: 5px;
  }

  .mission {
    transition-duration: 1s;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
  }

  .mission > div > h2 {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: white;
  }

  .mission > div > h3 {
    font-size: 13px;
    font-weight: bold;
    color: white;
  }

  .mission > div > p {
    font-size: 10px;
    color: white;
  }

  .leadership > img {
    border-radius: 15px;
    height: 150px;
    width: auto;
    box-shadow: 5px 5px 15px black;
  }
  .leadership > h1 {
    font-size: 25px;
  }

  .about_background {
    margin: 15px 25px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
  }

  .about_background > h2 {
    font-size: 17px;
  }

  .about_background > p {
    font-size: 12px;
    font-weight: 300;
  }

  .about_background > a {
    font-size: 12px;
    padding: 5px;
  }

  .outreach_content {
    display: flex;
    height: 100vh;
    padding: 0 20px;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .outreach-content-container {
    border: 1px solid black;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 15px;
  }

  .outreach-content-container > h1 {
    text-align: center;
    font-size: 22px;
    font-weight: 300;
  }

  .outreach-content-container > h2 {
    font-size: 15px;
  }

  .outreach-content-container > p {
    margin-bottom: 15px;
    font-size: 12px;
    margin-left: 10px;
    border-left: 2px solid white;
    padding-left: 5px;
    font-weight: 300;
  }

  .outreach-content-container > a {
    font-size: 12px;
    padding: 8px;
  }

  .purposeandpassion {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
  }

  .purpose-container {
    padding: 15px;
  }

  .purpose-container > h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
  }

  .purpose-container > h3 {
    font-size: 15px;
    font-weight: 300;
  }

  .whatwebeleive-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .whatwebeleive {
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .whatwebeleive > h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 400;
  }

  .what-we-beleive-option > h1 {
    width: 100%;
    text-align: left;
    font-size: 15px;
    padding: 2px 10px;
    height: 100%;
  }

  .plus-minus-icon {
    display: none;
  }

  .message > p {
    font-size: 15px;
    margin: 10px 0px;
  }
}

@media only screen and (max-width: 411px) {
  .up-arrow-icon {
    display: none;
  }
  .whoweare {
    padding: 15px;
    flex-direction: column;
  }
  .vision > h1 {
    font-size: 30px;
  }
  .vision > h2 {
    font-size: 20px;
  }
  .vision > p {
    font-size: 15px;
  }
  .vision > button {
    font-size: 10px;
    padding: 5px;
  }

  .mission {
    transition-duration: 1s;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
  }

  .mission > div > h2 {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: white;
  }

  .mission > div > h3 {
    font-size: 13px;
    font-weight: bold;
    color: white;
  }

  .mission > div > p {
    font-size: 10px;
    color: white;
  }

  .leadership > img {
    border-radius: 15px;
    height: 150px;
    width: auto;
    box-shadow: 5px 5px 15px black;
  }
  .leadership > h1 {
    font-size: 25px;
  }

  .about_background {
    margin: 15px 25px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
  }

  .about_background > h2 {
    font-size: 17px;
  }

  .about_background > p {
    font-size: 12px;
    font-weight: 300;
  }

  .about_background > a {
    font-size: 12px;
    padding: 5px;
  }

  .outreach_content {
    display: flex;
    height: 100vh;
    padding: 0 20px;
    justify-content: center;
    flex-direction: column;
  }

  .outreach-content-container {
    border: 1px solid black;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 15px;
  }

  .outreach-content-container > h1 {
    text-align: center;
    font-size: 22px;
    font-weight: 300;
  }

  .outreach-content-container > h2 {
    font-size: 15px;
  }

  .outreach-content-container > p {
    margin-bottom: 15px;
    font-size: 12px;
    margin-left: 10px;
    border-left: 2px solid white;
    padding-left: 5px;
    font-weight: 300;
  }

  .outreach-content-container > a {
    font-size: 12px;
    padding: 8px;
  }

  .purposeandpassion {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
  }

  .purpose-container {
    padding: 15px;
  }

  .purpose-container > h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
  }

  .purpose-container > h3 {
    font-size: 15px;
    font-weight: 300;
  }

  .whatwebeleive-container {
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .whatwebeleive {
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .whatwebeleive > h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 400;
  }

  .what-we-beleive-option > h1 {
    width: 100%;
    text-align: left;
    font-size: 15px;
    padding: 2px 10px;
    height: 100%;
  }

  .plus-minus-icon {
    display: none;
  }

  .message > p {
    font-size: 15px;
    margin: 10px 0px;
  }
}

@media only screen and (max-width: 414px) {
  .up-arrow-icon {
    display: none;
  }
  .whoweare {
    padding: 15px;
    flex-direction: column;
  }
  .vision > h1 {
    font-size: 30px;
  }
  .vision > h2 {
    font-size: 20px;
  }
  .vision > p {
    font-size: 15px;
  }
  .vision > button {
    font-size: 10px;
    padding: 5px;
  }

  .mission {
    transition-duration: 1s;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
  }

  .mission > div > h2 {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: white;
  }

  .mission > div > h3 {
    font-size: 13px;
    font-weight: bold;
    color: white;
  }

  .mission > div > p {
    font-size: 10px;
    color: white;
  }

  .leadership > img {
    border-radius: 15px;
    height: 150px;
    width: auto;
    box-shadow: 5px 5px 15px black;
  }
  .leadership > h1 {
    font-size: 25px;
  }

  .about_background {
    margin: 15px 25px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
  }

  .about_background > h2 {
    font-size: 17px;
  }

  .about_background > p {
    font-size: 12px;
    font-weight: 300;
  }

  .about_background > a {
    font-size: 12px;
    padding: 5px;
  }

  .outreach_content {
    display: flex;
    height: 100vh;
    padding: 0 20px;
    justify-content: center;
    flex-direction: column;
  }

  .outreach-content-container {
    border: 1px solid black;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 15px;
  }

  .outreach-content-container > h1 {
    text-align: center;
    font-size: 22px;
    font-weight: 300;
  }

  .outreach-content-container > h2 {
    font-size: 15px;
  }

  .outreach-content-container > p {
    margin-bottom: 15px;
    font-size: 12px;
    margin-left: 10px;
    border-left: 2px solid white;
    padding-left: 5px;
    font-weight: 300;
  }

  .outreach-content-container > a {
    font-size: 12px;
    padding: 8px;
  }

  .purposeandpassion {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
  }

  .purpose-container {
    padding: 15px;
  }

  .purpose-container > h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
  }

  .purpose-container > h3 {
    font-size: 15px;
    font-weight: 300;
  }

  .whatwebeleive-container {
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .whatwebeleive {
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .whatwebeleive > h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 400;
  }

  .what-we-beleive-option > h1 {
    width: 100%;
    text-align: left;
    font-size: 15px;
    padding: 2px 10px;
    height: 100%;
  }

  .plus-minus-icon {
    display: none;
  }

  .message > p {
    font-size: 15px;
    margin: 10px 0px;
  }

  .contact-background-color {
    border-radius: 20px;
    padding: 5px;
    height: auto;
    width: 95%;
  }
}

@media only screen and (max-width: 974px) {
  .up-arrow-icon {
    display: none;
  }
  .whoweare {
    padding: 15px;
    flex-direction: column;
  }
  .vision > h1 {
    font-size: 30px;
  }
  .vision > h2 {
    font-size: 20px;
  }
  .vision > p {
    font-size: 15px;
  }
  .vision > button {
    font-size: 10px;
    padding: 5px;
  }

  .mission {
    transition-duration: 1s;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
  }

  .mission > div > h2 {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: white;
  }

  .mission > div > h3 {
    font-size: 13px;
    font-weight: bold;
    color: white;
  }

  .mission > div > p {
    font-size: 10px;
    color: white;
  }

  .leadership > img {
    border-radius: 15px;
    height: 150px;
    width: auto;
    box-shadow: 5px 5px 15px black;
  }
  .leadership > h1 {
    font-size: 25px;
  }

  .about_background {
    margin: 15px 25px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
  }

  .about_background > h2 {
    font-size: 17px;
  }

  .about_background > p {
    font-size: 12px;
    font-weight: 300;
  }

  .about_background > a {
    font-size: 12px;
    padding: 5px;
  }

  .outreach_content {
    display: flex;
    height: 100vh;
    padding: 0 20px;
    justify-content: center;
    flex-direction: column;
  }

  .outreach-content-container {
    border: 1px solid black;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 15px;
  }

  .outreach-content-container > h1 {
    text-align: center;
    font-size: 22px;
    font-weight: 300;
  }

  .outreach-content-container > h2 {
    font-size: 15px;
  }

  .outreach-content-container > p {
    margin-bottom: 15px;
    font-size: 12px;
    margin-left: 10px;
    border-left: 2px solid white;
    padding-left: 5px;
    font-weight: 300;
  }

  .outreach-content-container > a {
    font-size: 12px;
    padding: 8px;
  }

  .purposeandpassion {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
  }

  .purpose-container {
    padding: 15px;
  }

  .purpose-container > h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
  }

  .purpose-container > h3 {
    font-size: 15px;
    font-weight: 300;
  }

  .whatwebeleive-container {
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .whatwebeleive {
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .whatwebeleive > h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 400;
  }

  .what-we-beleive-option > h1 {
    width: 100%;
    text-align: left;
    font-size: 15px;
    padding: 2px 10px;
    height: 100%;
  }

  .plus-minus-icon {
    display: none;
  }

  .message > p {
    font-size: 15px;
    margin: 10px 0px;
  }

  .contact-background-color {
    border-radius: 20px;
    padding: 5px;
    height: auto;
    width: 95%;
  }
}
