@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap");

.media {
  height: 100vh;
  width: 100vw;
  padding-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.top-image {
  background-image: url("../assets/images/media.jpg");
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 80px;
  height: 30vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-image > h1 {
  color: white;
  font-size: 50px;
  font-family: "Raleway", sans-serif;
}

.media-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.media-container > h1 {
  font-size: 35px;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
}

.media-top-container {
  border: 1px solid black;
  height: 200px;
  width: 800px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.media-bot-container {
}
