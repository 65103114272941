.connect {
  height: 100vh;
  width: 100%;
  background-image: url("../assets/images/connectbackground.png");
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connect-container {
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 10px;
}

.connect-container > img {
  width: 70%;
  height: 50%;
}

.connect-container > h1 {
  font-size: 35px;
  font-weight: bold;
}

.connect-container > p {
  font-size: 25px;
}

@media only screen and (max-width: 375px) {
  .connect-container > h1 {
    font-size: 20px;
    font-weight: bold;
  }

  .connect-container > p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 414px) {
  .connect-container > h1 {
    font-size: 25px;
    font-weight: bold;
  }

  .connect-container > p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 540px) {
  .connect-container > h1 {
    font-size: 25px;
    font-weight: bold;
  }

  .connect-container > p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 280px) {
    .connect-container > h1 {
      font-size: 20px;
      font-weight: bold;
    }
  
    .connect-container > p {
      font-size: 15px;
    }
  }
