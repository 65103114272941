@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap");

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 200px;
  z-index: 1;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  position: fixed;
  z-index: 2;
  left: 50%;
  top: 3%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 80px;
  transition-duration: 400ms;
  white-space: nowrap;
}

.navigation-tab {
  background-color: white;
  border-radius: 10px 0 10px 0;
  transition: all 500ms;
}

.navigation-tab:hover {
  border-radius: 10px 10px 0 0;
}

.hide-navigation > .logo > img {
  transform: translate(0, -80px);
}

.hide-navigation {
  height: 40px;
}

.logo {
  flex: 0.25;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 3px;
  left: 15%;
}

.banner {
  margin-left: 5px;
  height: 60px;
}

.logo_img {
  height: 75px;
}

.navigation_links {
  transition-duration: 400ms;
  position: absolute;
  width: 500px;
  left: 37%;
  top: 0;
  font-size: 20px;
  flex: 0.75;
  width: 50%;
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  height: 100%;
}
.hide-navigation > .navigation_links {
  margin: auto;
  left: 0;
  right: 0;
}
.dropdown-about {
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  top: 80px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-bottom: 2px solid gray;
  border-left: 2px solid gray;
  overflow: hidden;
  width: 400px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
}

.home {
  padding-top: 25px;
  font-size: 20px;
  padding-bottom: 25px;
  transition-duration: 400ms;
}

.link {
  padding: 5px 20px;
  transition: all 1s;
}

.link:hover {

}

.about-menu {
  height: 85px;
  display: grid;
  align-items: center;
  transition: all;
  padding-top: 20px;
  padding-bottom: 20px;
  transition-duration: 400ms;
}

.about-menu h4 {
  transition-duration: 400ms;
}

.about-menu h4:hover {
  font-size: 22px;
  font-weight: 500;
}

.about-menu:hover .dropdown-about {
  display: flex;
}

.about_link {
  transition-duration: 300ms;
  font-size: 20px;
  padding: 5px;
  width: 100%;
  text-align: center;
  font-weight: 100;
}

.about_link:hover {
  color: black;
  font-weight: 500;
}

.videos {
  width: 20%;
}

@media only screen and (max-width: 414px) {
  .navigation {
    height: 40px;
    justify-content: space-between;
    width: 100%;
  }

  .navigation-tab {
    padding-left: 5px;
    padding-right: 5px;
  }

  .link {
    font-size: 12px;
    padding: 0;
  }
}

@media only screen and (max-width: 411px) {
  .navigation {
    height: 40px;
    padding: 0px 5%;
    justify-content: space-around;
    width: 100%;
  }

  .link {
    font-size: 12px;
    padding: 0;
  }
}

@media only screen and (max-width: 360px) {
  .navigation {
    height: 40px;
    justify-content: space-around;
    width: 100%;
  }

  .link {
    padding: 0;
    font-size: 12px;
  }

  .active {
    font-weight: 200;
  }
}
