.whywegive {
  background-image: url("../assets/images/whywegive.jpg");
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  color: white;
  padding-left: 20%;
  padding-right: 20%;
  flex-direction: column;
}

.whywegive > h2 {
font-size: 25px;
}


.whywegive > ul > li {
  font-size: 20px;
  font-weight: 300;
}

@media only screen and (max-width: 883px) {
    .whywegive {
        padding-left: 10%;
        padding-right: 10%;
      }
  }