@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap");

.whoweare {
  background-image: url("../assets/images/who-we-are.jpg");
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding-left: 20%;
  padding-right: 20%;
}



h1,
p {
  font-family: "Josefin Sans";
}

.about-new-nolm {
  margin-top: 80px;
}

.about-new-nolm > h1 {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}

p {
  font-size: 20px;
}

.our-vision,
.about-new-nolm,
.our-mission {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.95);
  border-radius: 10px;
  margin-bottom: 30px;
}

.our-vision,
.our-mission {
  position: relative;
  padding-top: 50px;
}

.our-vision > img {
  position: absolute;
  top: -50%;
  width: 375px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  border-radius: 10px;
}

.our-mission > img {
  position: absolute;
  top: -45px;
  width: 375px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  border-radius: 10px;
}
@media only screen and (max-width: 1253px) {
  .whoweare {
    height: auto;
  }
}

@media only screen and (max-width: 883px) {
  .our-vision > img {
    top: -40%;
  }
}
@media only screen and (max-width: 699px) {
  .about-new-nolm {
    margin-bottom: 50px;
  }
  .our-vision {
    padding-top: 40px;
  }
  .whoweare {
    padding-left: 100px;
    padding-right: 100px;
  }
  .our-vision > img {
    top: -70%;
  }
  .about-new-nolm > h1 {
    font-size: 25px;
    font-weight: bold;
  }
  p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 699px) {
  .our-vision > img {
    top: -60%;
  }
}


@media only screen and (max-width: 511px) {
  .our-vision > img {
    top: -50%;
  }
}

@media only screen and (max-width: 447px) {
  .our-vision > img {
    top: -40%;
  }
}

@media only screen and (max-width: 414px) {
  .about-new-nolm > h1 {
    font-size: 25px;
    font-weight: bold;
  }
  p {
    font-size: 15px;
  }
  .whoweare {
    padding-left: 50px;
    padding-right: 50px;
  }
  .our-vision > img {
    top: -50%;
  }
  .our-mission {
    position: relative;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 375px) {
  .about-new-nolm > h1 {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    font-size: 15px;
  }
  .our-vision > img {
    top: -50%;
  }
  .our-mission {
    position: relative;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 335px) {
  .about-new-nolm > h1 {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    font-size: 15px;
  }
  .whoweare {
    padding-left: 25px;
    padding-right: 25px;
  }
  .our-vision > img {
    top: -50%;
  }
  .our-mission {
    position: relative;
    padding-top: 30px;
  }
}
