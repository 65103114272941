@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

.newtonolm {
  height: 100vh;
  width: 100%;
  background-image: url("../assets/images/newtonolm.jpg");
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newtonolm-container {
  padding: 15px;
  margin: 20px;
  margin-top: 100px;
  width: 90%;
  color: white;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
}

.newtonolm-container > h1 {
  font-family: "Josefin Sans";
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.blocks-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.block {
  margin-bottom: 10px;
  height: 400px;
  width: 400px;
  box-shadow: 7px 7px 15px black;
  transition-duration: 400ms;
  margin-bottom: 20px;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 10px;
}

.block:hover {
  transform: scale(1.1);
}

.block > h1 {
  font-family: "Josefin Sans";
  font-size: 25px;
  font-weight: bold;
}

.block > p {
  font-family: "Josefin Sans";
  font-size: 20px;
}

@media only screen and (max-width: 912px) {
  .blocks-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  .newtonolm {
    height: auto;
  }
  .blocks-container {
    flex-direction: column;
  }
  .newtonolm-container {
    padding: 15px;
    margin: 60px 0 20px 0;
    width: 70%;
    color: white;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .newtonolm-container > h1 {
    font-family: "Josefin Sans";
    font-size: 50px;
    text-align: center;
    font-weight: bold;
  }

  .block {
    margin-bottom: 10px;
    width: 90%;
    height: auto;
  }

  .block > h1 {
    font-family: "Josefin Sans";
    font-size: 35px;
    font-weight: bold;
  }

  .block > p {
    font-family: "Josefin Sans";
    font-size: 25px;
  }
}

@media only screen and (max-width: 820px) {
  .newtonolm {
    height: auto;
  }
  .newtonolm-container {
    padding: 15px;
    margin: 60px 0 20px 0;
    width: 70%;
    color: white;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .newtonolm-container > h1 {
    font-family: "Josefin Sans";
    font-size: 50px;
    text-align: center;
    font-weight: bold;
  }

  .block {
    margin-bottom: 10px;
  }

  .block > h1 {
    font-family: "Josefin Sans";
    font-size: 35px;
    font-weight: bold;
  }

  .block > p {
    font-family: "Josefin Sans";
    font-size: 25px;
  }
}

@media only screen and (max-width: 414px) {
  .newtonolm {
    height: auto;
  }
  .newtonolm-container {
    padding: 15px;
    margin: 50px 0 20px 0;
    width: 80%;
    color: white;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .newtonolm-container > h1 {
    font-family: "Josefin Sans";
    font-size: 25px;
    text-align: center;
    font-weight: bold;
  }

  .block {
    margin-bottom: 10px;
  }

  .block > h1 {
    font-family: "Josefin Sans";
    font-size: 20px;
    font-weight: bold;
  }

  .block > p {
    font-family: "Josefin Sans";
    font-size: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .newtonolm {
    height: auto;
  }
  .newtonolm-container {
    padding: 15px;
    margin: 50px 0 20px 0;
    width: 80%;
    color: white;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .newtonolm-container > h1 {
    font-family: "Josefin Sans";
    font-size: 25px;
    text-align: center;
    font-weight: bold;
  }

  .block {
    margin-bottom: 10px;
  }

  .block > h1 {
    font-family: "Josefin Sans";
    font-size: 20px;
    font-weight: bold;
  }

  .block > p {
    font-family: "Josefin Sans";
    font-size: 15px;
  }
}
