@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap");

.contact {
  background-image: url("../assets/images/contactwallpaper.jpg");
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.contact-container {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
}

.contact-container > h1 {
  font-family: "Josefin Sans";
  font-size: 45px;
  text-align: center;
  font-weight: bold;
}

.contact-name {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.contact-name-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.contact-key {
  white-space: nowrap;
}

.contact-value {
  justify-content: center;
  white-space: nowrap;
  margin-left: 10px;
  font-weight: 300;
}

h2 {
  margin-bottom: 5px;
  font-size: 23px;
}

.contact-icon {
  transform: scale(1.5);
  margin-right: 10px;
}

@media only screen and (max-width: 843px) {
  h2 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 851px) {
  .contact-name {
    margin-right: 0px;
  }

  h2 {
    margin-bottom: 5px;
  }

  .contact-name > h2 {
    font-size: 17px;
    white-space: nowrap;
  }

  .contact-value > h2 {
    font-size: 17px;
    white-space: nowrap;
  }

  .contact-icon {
    transform: scale(1.2);
    margin-right: 5px;
  }
}

@media only screen and (max-width: 677px) {
  .contact-container {
    padding: 25px;
  }
  .contact-name {
    margin-right: 0px;
  }

  h2 {
    margin-bottom: 5px;
  }

  .contact-name > h2 {
    font-size: 15px;
    white-space: nowrap;
  }

  .contact-value > h2 {
    font-size: 15px;
    white-space: nowrap;
  }

  .contact-icon {
    transform: scale(1.2);
    margin-right: 5px;
  }
}

@media only screen and (max-width: 585px) {
  .contact-container {
    padding: 25px;
  }

  .contact-container > h1 {
    font-size: 30px;
  }

  .contact-name {
    margin-right: 0px;
  }

  h2 {
    margin-bottom: 5px;
  }

  .contact-name > h2 {
    font-size: 12px;
    white-space: nowrap;
  }

  .contact-value > h2 {
    font-size: 12px;
    white-space: nowrap;
  }

  .contact-icon {
    transform: scale(1.2);
    margin-right: 5px;
  }
}

@media only screen and (max-width: 535px) {
  h2 {
    font-size:14px;
  }
}

@media only screen and (max-width: 481px) {
  h2 {
    font-size:12px;
  }
}

@media only screen and (max-width: 429px) {
  h2 {
    font-size:10px;
  }
}

@media only screen and (max-width: 414px) {
  
    .contact-container > h1 {
      font-size: 35px;
      margin-bottom: 20px;
    }
  
    .contact-value > h2 {
      font-size: 15px;
      white-space: nowrap;
    }
  }

@media only screen and (max-width: 375px) {
  
    .contact-container > h1 {
      font-size: 35px;
      margin-bottom: 20px;
    }
  
    .contact-value > h2 {
      font-size: 12px;
      white-space: nowrap;
    }
  }

@media only screen and (max-width: 320px) {
  .contact-name {
    display: none;
  }

  .contact-container > h1 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .contact-value > h2 {
    font-size: 10px;
    white-space: nowrap;
  }
}
