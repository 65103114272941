.NOLMPrayerTeam {
    height: 100vh;
    width: 100%;
    font-family: "Josefin Sans";
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    flex-direction: column;
}

.NOLMPrayerTeam > img {
    border-radius: 20px;
}

.NOLMPrayerTeam > .team {
    margin-top: 15px;
    width: 500px;
    white-space: nowrap;
}

@media only screen and (max-width: 430px) {
    .NOLMPrayerTeam > img {
        width: 90%;
    }

    .NOLMPrayerTeam > .team {
        width: 350px;
    }
}

@media only screen and (max-width: 414px) {
    .NOLMPrayerTeam > img {
        width: 90%;
    }

    .NOLMPrayerTeam > .team {
        width: 350px;
    }
}

@media only screen and (max-width: 375px) {
    .NOLMPrayerTeam > img {
        width: 90%;
    }

    .NOLMPrayerTeam > .team {
        width: 350px;
    }
}

@media only screen and (max-width: 360px) {
    .NOLMPrayerTeam > img {
        width: 90%;
    }

    .NOLMPrayerTeam > .team {
        width: 350px;
    }
}


