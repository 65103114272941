@import url("https://fonts.googleapis.com/css2?family=Anton&family=Praise&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Yanone+Kaffeesatz:wght@200;300;400;500;600;700&display=swap");

.prayer_request {
  background-image: url("../assets/images/vision.jpg");
  background-position: center;
  background-repeat: no-repeat;
  padding: 80px 100px;
  height: auto;
  width: 100%;
}

.pr-top-container,
.submission-container {
  background-color: rgba(255, 255, 255, 0.7);
}

.pr-top-container {
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;
  padding: 50px;
}

.pr-top-container > h1 {
  font-size: 50px;
  font-weight: bold;
}

.submission-container {
  border-radius: 10px;
  padding: 20px;
}

.message_3,
.prayer_request_container,
.email,
.phone-number {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.h2-element {
  font-size: 30px;
  font-weight: bold;
}

.p-element {
  font-size: 20px;
  border-left: 5px solid #a4508b;
  padding-left: 10px;
}

@media only screen and (max-width: 414px) {
  .prayer_request {
    padding: 5px 5px;
    height: auto;
    width: 100vw;
  }

  .pr-top-container {
    border-radius: 10px;
    margin-top: 40px;
    text-align: center;
    padding: 20px;
  }

  .pr-top-container > h1 {
    font-size: 25px;
    font-weight: bold;
  }

  .submission-container {
    border-radius: 10px;
    padding: 20px;
  }

  .h2-element {
    font-size: 20px;
    font-weight: bold;
  }

  .p-element {
    font-size: 15px;
    border-left: 5px solid #a4508b;
    padding-left: 10px;
  }
}
