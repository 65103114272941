.team {
  width: 300px;
  height: 400px;
  margin: 50px;
  flex-direction: column;
}

.team img {
    width: 400px;
    height: 300px;
    object-fit: cover;
}

.team h3 {
  font-size: 25px;
}

.team h4 {
  font-size: 20px;
}

.team button {
  text-align: left;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.team button:hover {
    text-decoration: underline;
}

.modal-team{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    box-shadow: 24px;
    padding: 20px;
    border-radius: 10px;
}

.modal-team img {
    border-radius: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.modal-team h3 {
    font-size: 35px;
}

.modal-team h4 {
    font-size: 20px;
    margin-bottom: 10px;
}

.modal-team p {
    font-size: 20px;
    font-weight: 300;
}

@media (max-width: 515px) {
    .modal-team{
        width: 400px;
    }
    
    .modal-team h3 {
        font-size: 30px;
    }
    
    .modal-team h4 {
        font-size: 15px;
    }
    
    .modal-team p {
        font-size: 15px;
    }
}

@media (max-width: 501px) {
    .team {
        width: 90%;
        margin: 0;
        height: auto;
      }
      
      .team h3 {
        font-size: 25px;
      }
      
      .team h4 {
        font-size: 20px;
      }
      
      .team button {
        text-align: left;
        font-size: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
      
      .team button:hover {
          text-decoration: underline;
      }
}

@media (max-width: 413px) {
    .modal-team{
        width: 350px;
    }
}

@media (max-width: 357px) {
    .modal-team{
        width: 325px;
    }
}

@media (max-width: 327px) {
    .modal-team{
        width: 300px;
    }
}