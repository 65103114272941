@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap");

.food-pantry-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.top-banner {
  width: 100vw;
  height: 30vh;
  background-image: url("../assets/images/image8.jpg");
  background-position: center center;
  background-size: cover;
  -moz-box-shadow: inset 0 0 25px #000000;
  -webkit-box-shadow: inset 0 0 25px #000000;
  box-shadow: inset 0 0 50px #000000;
}

.download {
  display: flex;
  align-items: center;
  justify-content: center;
}
.download > h1 {
  white-space: nowrap;
  font-size: 25px;
  font-family: "Anton", sans-serif;
}

.left-content {
  flex: 0.55;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  background-image: url("../assets/images/helpwallpaper.jpg");
  background-position: center center;
  background-size: cover;
}

.container {
  width: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: black;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 7px 7px 15px black;
  transition-duration: 400ms;
}

.container:hover {
  transform: scale(1.1);
  z-index: 3;
}

.container > a {
  width: auto;
  text-align: center;
  color: white;
  font-family: "Anton", sans-serif;
  font-size: 20px;
  background-color: black;
  padding: 0 20px 0 20px;
  border-radius: 20px;
  margin-bottom: 5px;
}

.container > h1 {
  text-align: left;
  width: 100%;
  font-size: 25px;
  font-family: "Anton", sans-serif;
}

.mg_bottom {
  margin-bottom: 20px;
}

.container > span {
  font-weight: bold;
  font-style: italic;
}

.container > h2 {
  flex-direction: column;
  text-align: left;
}

.download > a {
  width: auto;
  text-align: center;
  color: white;
  font-family: "Anton", sans-serif;
  font-size: 20px;
  background-color: black;
  padding: 0 20px 0 20px;
  border-radius: 20px;
  margin-bottom: 5px;
}

.container > a:hover {
  background-color: rgb(34, 34, 34);
  color: white;
}

.right-image {
  flex: 0.45;
  height: 100%;
  width: 100%;
  background-image: url("../assets/images/canned-foods.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-img-container {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 20px;
  padding: 20px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-img-container img {
  border-radius: 50%;
  width: 190px;
  margin: 10px;
}

.right-img-container > h2 {
  font-size: 25px;
  font-family: "Josefin Sans";
  font-weight: bold;
}

.right-img-container > p {
  margin-bottom: 25px;
  font-family: "Josefin Sans";
  font-size: 15px;
  margin-left: 50px;
  border-left: 5px solid white;
  padding-left: 10px;
}

.img-container {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.img-container > img {
  border-radius: 50%;
  width: 40%;
}

.direction_container {
  display: flex;
}

@media only screen and (max-width: 800px) {
  .top-banner {
    display: none;
  }
  .food-pantry {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .left-content {
    flex: 1;
  }

  .container {
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: black;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 7px 7px 15px black;
    transition-duration: 400ms;
  }

  .container > div {
    font-size: 13px;
  }

  .container:hover {
    transform: scale(1.1);
    z-index: 3;
  }

  .container > h1 {
    text-align: left;
    width: 100%;
    font-size: 20px;
    font-family: "Anton", sans-serif;
  }

  .mg_bottom {
    margin-bottom: 20px;
  }

  .container > span {
    font-weight: bold;
    font-style: italic;
  }

  .container > h2 {
    flex-direction: column;
    text-align: left;
  }

  .container > a {
    width: 100%;
    text-align: center;
    color: white;
    font-family: "Anton", sans-serif;
    font-size: 20px;
    background-color: black;
    padding: 0 20px 0 20px;
    border-radius: 20px;
    margin-bottom: 5px;
  }

  .container > a:hover {
    background-color: rgb(34, 34, 34);
    color: white;
  }

  .right-image {
    flex: 0.45;
    height: 100vh;
    width: 100%;
    background-image: url("../assets/images/canned-foods.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .direction_container {
    display: flex;
  }
  .right-image {
    display: none;
  }
}

@media only screen and (max-height: 525px) {
  .food-pantry-container {
    height: 150vh;
  }
}

@media only screen and (max-width: 414px) {
  .top-banner {
    display: none;
  }
  .food-pantry {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .left-content {
    flex: 1;
  }

  .container {
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: black;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 7px 7px 15px black;
    transition-duration: 400ms;
  }

  .container > div {
    font-size: 13px;
  }

  .container:hover {
    transform: scale(1.1);
    z-index: 3;
  }

  .container > h1 {
    text-align: left;
    width: 100%;
    font-size: 20px;
    font-family: "Anton", sans-serif;
  }

  .mg_bottom {
    margin-bottom: 20px;
  }

  .container > span {
    font-weight: bold;
    font-style: italic;
  }

  .container > h2 {
    flex-direction: column;
    text-align: left;
  }

  .container > a {
    width: 100%;
    text-align: center;
    color: white;
    font-family: "Anton", sans-serif;
    font-size: 20px;
    background-color: black;
    padding: 0 20px 0 20px;
    border-radius: 20px;
    margin-bottom: 5px;
  }

  .container > a:hover {
    background-color: rgb(34, 34, 34);
    color: white;
  }

  .right-image {
    flex: 0.45;
    height: 100vh;
    width: 100%;
    background-image: url("../assets/images/canned-foods.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .direction_container {
    display: flex;
  }
  .right-image {
    display: none;
  }
}

@media only screen and (max-height: 411px) {
  .food-pantry-container {
    height: 160vh;
  }
}


@media only screen and (max-height: 360px) {
  .food-pantry-container {
    height: 180vh;
  }
}

@media only screen and (max-height: 335px) {
  .food-pantry-container {
    height: 250vh;
  }
}

@media only screen and (max-width: 320px) {
  .top-banner {
    display: none;
  }
  .food-pantry {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .left-content {
    flex: 1;
  }

  .download > h1 {
    font-size: 15px;
  }
  .download > a {
    font-size: 15px;
  }

  .container {
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: black;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 7px 7px 15px black;
    transition-duration: 400ms;
  }

  .container > div {
    font-size: 11px;
  }

  .container:hover {
    transform: scale(1.1);
    z-index: 3;
  }

  .container > h1 {
    text-align: left;
    width: 100%;
    font-size: 20px;
    font-family: "Anton", sans-serif;
  }

  .mg_bottom {
    margin-bottom: 20px;
  }

  .container > span {
    font-weight: bold;
    font-style: italic;
  }

  .container > h2 {
    flex-direction: column;
    text-align: left;
  }

  .container > a {
    width: 100%;
    text-align: center;
    color: white;
    font-family: "Anton", sans-serif;
    font-size: 15px;
    background-color: black;
    padding: 0 20px 0 20px;
    border-radius: 20px;
    margin-bottom: 5px;
  }

  .container > a:hover {
    background-color: rgb(34, 34, 34);
    color: white;
  }

  .right-image {
    flex: 0.45;
    height: 100vh;
    width: 100%;
    background-image: url("../assets/images/canned-foods.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .direction_container {
    display: flex;
  }
  .right-image {
    display: none;
  }
}
