@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap");

.outreach {
  background-image: url("../assets/images/outreach-bg.jpg");
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
}

.outreach-container {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.outreach-container img {
  margin-bottom: 10px;
  width: 700px;
}

.outreach-container > p {
  font-family: "Josefin Sans";
  font-size: 25px;
  text-align: center;
}

@media only screen and (max-width: 699px) {
  .outreach-container > p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 414px) {
  .outreach-container > p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 320px) {
  .outreach-container > p {
    font-family: "Josefin Sans";
    font-size: 15px;
    text-align: center;
  }
  .outreach-container {
    bottom: 10px;
    width: 60%;
  }
}
