.volunteer {
  background-image: url(../assets/images/Picture3.jpg);
  background-position: center center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
}

.volunteer-container {
  width: 600px;
  height: auto;
  position: absolute;
  left: 100px;
  top: 150px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  padding: 20px;
  color: white;
}

.volunteer-container h1 {
  font-size: 35px;
}

.pledge-to-volunteer {
  margin-top: 20px;
}

.pledge-to-volunteer form {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pledge-to-volunteer form input {
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 10px;
  color: black;
  outline: none;
  border: none;
}

.pledge-to-volunteer form input:last-child {
  background-color: #b874a3;
  color: white;
  cursor: pointer;
}

.pledge-to-volunteer form input:last-child:hover {
  background-color: #c063a2;
}

@media (max-width: 768px) {
  .volunteer-container {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-height: 629px) {
  .volunteer {
    height: 120vh;
  }
}

@media (max-height: 527px) {
  .volunteer {
    height: 130vh;
  }
}



@media (max-width: 414px) {
    .volunteer {
      height: 120vh;
      padding-top: 50px;
    }
    
    .volunteer-container {
      top: 0px;
      width: 90%;
      position: relative;
      color: white;
    }
  
    .volunteer-container h1 {
      font-size: 40px;
      font-weight: bold;
    }
  
    .volunteer-container p {
      font-size: 25px;
    }
  
    .pledge-to-volunteer form input {
      padding: 10px;
    }
  
    .pledge-to-volunteer form input:last-child {
      background-color: #b874a3;
      color: white;
    }
  }

@media (max-width: 375px) {
    .volunteer {
      height: 100vh;
      padding-top: 40px;
    }
    
    .volunteer-container {
      top: 0px;
      width: 90%;
      position: relative;
      color: white;
    }
  
    .volunteer-container h1 {
      font-size: 30px;
      font-weight: bold;
    }
  
    .volunteer-container p {
      font-size: 20px;
    }
  
    .pledge-to-volunteer form input {
      padding: 5px;
    }
  
    .pledge-to-volunteer form input:last-child {
      background-color: #b874a3;
      color: white;
    }
  }

@media (max-width: 320px) {
  .volunteer {
    height: 100vh;
    padding-top: 40px;
  }

  .volunteer-container {
    top: 0px;
    width: 90%;
    position: relative;
    color: white;
  }

  .volunteer-container h1 {
    font-size: 20px;
    font-weight: bold;
  }

  .volunteer-container p {
    font-size: 15px;
  }

  .pledge-to-volunteer form input {
    padding: 5px;
  }

  .pledge-to-volunteer form input:last-child {
    background-color: #b874a3;
    color: white;
  }
}
