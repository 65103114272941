@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap");

.grow-groups {
  background-image: url("../assets/images/grow-groups.jpg");
  height: auto;
  width: 100%;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 10%;
}

.grow-group-container {
  color: white;
  display: flex;
  width: 70%;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  margin-top: 60px;
  margin-bottom: 60px;
}

.grow-group-container > img {
  width: 80%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

.grow-group-container > h1 {
  font-size: 40px;
  font-family: "Josefin Sans";
  margin-bottom: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grow-group-container > p {
  font-size: 22px;
  font-family: "Josefin Sans";
  margin-bottom: 10px;
}

.video-icon {
  transform: scale(1.5);
  margin-right: 5px;
}

.bookmark-icon {
  transform: scale(2);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-items: center;
}

.grow-group-container > a {
  font-size: 25px;
  font-family: "Josefin Sans";
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d8cff;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 912px) {
  .grow-group-container {
    width: 95%;
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .grow-group-container > h1 {
    font-size: 45px;
  }

  .grow-group-container > p {
    font-size: 35px;
  }

  .video-icon {
    transform: scale(1.3);
  }

  .bookmark-icon {
    transform: scale(1.3);
  }

  .grow-group-container > a {
    font-size: 35px;
  }
}

@media only screen and (max-width: 820px) {
  .grow-group-container {
    width: 95%;
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .grow-group-container > h1 {
    font-size: 30px;
  }

  .grow-group-container > p {
    font-size: 20px;
  }

  .video-icon {
    transform: scale(1.3);
  }

  .bookmark-icon {
    transform: scale(1.3);
  }

  .grow-group-container > a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 414px) {
  .grow-group-container {
    width: 95%;
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .grow-group-container > h1 {
    font-size: 20px;
  }

  .grow-group-container > p {
    font-size: 15px;
  }

  .video-icon {
    transform: scale(1.3);
  }

  .bookmark-icon {
    transform: scale(1.3);
  }

  .grow-group-container > a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .grow-group-container {
    width: 95%;
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .grow-group-container > h1 {
    font-size: 20px;
  }

  .grow-group-container > p {
    font-size: 15px;
  }

  .video-icon {
    transform: scale(1.3);
  }

  .bookmark-icon {
    transform: scale(1.3);
  }

  .grow-group-container > a {
    font-size: 15px;
    font-family: "Josefin Sans";
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2d8cff;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
