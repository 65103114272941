.dream-team {
  height: auto;
  width: 100%;
}

.dream-team-header {
  background-image: url(../assets/images/meet-our-team.jpg);
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dream-team-header h2 {
  font-size: 64px;
  text-align: center;
  color: rgb(32, 32, 32);
}

.dream-team-container {
  padding: 50px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media (max-width: 507px) {
  .dream-team-header h2 {
    font-size: 50px;
  }
}
@media (max-width: 397px) {
  .dream-team-header h2 {
    font-size: 40px;
  }
}
