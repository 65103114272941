.housing {
  background-image: url(../assets/images/housing-background.jpg);
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.housing-container {
  color: white;
  padding: 20px;
  background-color: rgba(0,0,0,0.4);
  margin: 20%;
  border-radius: 10px;
}

.housing-title {
    font-size: 30px;
    font-weight: bold;
}

.housing-img-container {
  display: flex;
  justify-content: center;
}

.housing-img-container > img {
  border-radius: 50%;
  width: 300px;
}

@media only screen and (max-width: 800px) {
    .housing-container {
        color: white;
        padding: 10px;
        background-color: rgba(0,0,0,0.8);
        margin: 10%;
        border-radius: 10px;
      }
    .housing-title {
        font-size: 25px;
    }
    .housing-img-container > img {
        border-radius: 50%;
        width: 200px;
      }
}
