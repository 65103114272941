.footer {
    height: 100px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.social-icons {
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.social-icons a {
    margin: 5px;
}

.footer > a > img {
    height: 53px;
    width: 53px;
}

.footer > p {
    color: black;
    font-size: 15px;
    white-space: nowrap;
}


@media only screen and (max-width: 520px) {
    .footer > a > img {
        height: 25px;
        width: 25px;
    }
    .footer > p {
        color: black;
        font-size: 10px;
    }
}